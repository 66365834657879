import React, { useState, useEffect, createContext } from "react"
import { Route, Routes as Switch, Outlet, useNavigate, useSearchParams } from "react-router-dom"
import { Routes } from "../routes"
import DashboardOverview from "./dashboard/DashboardOverview"
import FanDashboard from "./fan/Fan"
import FanHistory from "./fan/History"
import ParkingfeeDashboard from "./parkingfee/Parkingfee"
import ParkingfeeHistory from "./parkingfee/History"
import Withdraw from "./transfer/Withdraw"
import TransferHistory from "./transfer/History"
import Profile from "./Profile"
import Setting from "./Setting"

import Sidebar from "../components/Sidebar"
import Navbar from "../components/Navbar"
import Preloader from "../components/Preloader"

export const UserContext = createContext(null)

const RouteWithSidebar = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false)
    const [user, setUser] = useState(false)

    useEffect(() => {
        async function checkLogin() {
            let response = await fetch("https://one-api.just-id.com/auth/me", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            let data = await response.json()
            if (data && data.user) {
                setUser(data.user)
                setLoaded(true)
            } else {
                window.location =
                    "https://auth.just-id.com/login?client_id=justid-one-web-auth&redirect_uri=https://just-id.one/callback&response_type=code"
            }
        }

        checkLogin()
    }, [])

    const localStorageIsSettingsVisible = () => {
        return localStorage.getItem("settingsVisible") === "false"
            ? false
            : true
    }

    const [showSettings, setShowSettings] = useState(
        localStorageIsSettingsVisible
    )

    const toggleSettings = () => {
        setShowSettings(!showSettings)
        localStorage.setItem("settingsVisible", !showSettings)
    }

    const signOut = () => {
        localStorage.removeItem("accessToken")
        window.location = "/"
    }

    return (
        <>
            <Preloader show={loaded ? false : true} />
            <Sidebar />

            <main className="content">
                <UserContext.Provider value={{ user, signOut }}>
                    <Navbar />
                    <Outlet />
                </UserContext.Provider>
            </main>
        </>
    )
}
const Callback = () => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
    useEffect(() => {
        async function fetchData() {
            let code = searchParams.get("code")
            let response = await fetch("https://one-api.just-id.com/login", {
                method: "POST",
                body: JSON.stringify({ code }),
                headers: { "Content-Type": "application/json" },
            })
            let data = await response.json()
			if(data && data.accessToken) {
				localStorage.setItem('accessToken', data.accessToken)
				navigate("/dashboard/overview")
			}
        }
        fetchData()
    }, [])
    return (
        <div>
            <h2>Callback</h2>
        </div>
    )
}

export default () => (
    <Switch>
		<Route exact path={Routes.Callback.path} element={<Callback />}/>
        <Route element={<RouteWithSidebar />}>
            <Route
                exact
                path={Routes.DashboardOverview.path}
                element={<DashboardOverview />}
            />
            <Route
                exact
                path={Routes.FanDashboard.path}
                element={<FanDashboard />}
            />
            <Route
                exact
                path={Routes.FanHistory.path}
                element={<FanHistory />}
            />
            <Route
                exact
                path={Routes.ParkingfeeDashboard.path}
                element={<ParkingfeeDashboard />}
            />
            <Route
                exact
                path={Routes.ParkingfeeHistory.path}
                element={<ParkingfeeHistory />}
            />
            <Route exact path={Routes.Withdraw.path} element={<Withdraw />} />
            <Route
                exact
                path={Routes.TransferHistory.path}
                element={<TransferHistory />}
            />
			<Route
                exact
                path={Routes.Profile.path}
                element={<Profile />}
            />
			<Route
                exact
                path={Routes.Setting.path}
                element={<Setting />}
            />
            <Route
                exact
                path={Routes.Presentation.path}
                element={<DashboardOverview />}
            />
        </Route>
    </Switch>
)
