import React from "react"
import './Stepper.css'

export default ({ step }) => {
    return (
        <div className="md-stepper-horizontal green">
            <div className={`md-step ${step >= 1 && "active"}`}>
                <div className="md-step-circle"><span>1</span></div>
                <div className="md-step-title">Description</div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
            </div>
            <div className={`md-step ${step >= 2 && "active"}`}>
                <div className="md-step-circle"><span>2</span></div>
                <div className="md-step-title">Account</div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
            </div>
            <div className={`md-step ${step >= 3 && "active"}`}>
                <div className="md-step-circle"><span>3</span></div>
                <div className="md-step-title">Check {'&'} Confirm</div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
            </div>
            <div className={`md-step ${step >= 4 && "active"}`}>
                <div className="md-step-circle"><span>4</span></div>
                <div className="md-step-title">Conclusion</div>
                <div className="md-step-bar-left"></div>
                <div className="md-step-bar-right"></div>
            </div>
        </div>
    )
}
