import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import dayjs from 'dayjs'
import { Breadcrumb, Card, Table, Spinner } from "@themesberg/react-bootstrap"
import config from "./../../config"
import Pagination from './../../components/Pagination'
import numeral from "numeral"

async function loadTransfers({ page }) {
    let response = await fetch(`${config.API_URL}/transfer/histories?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " + localStorage.getItem("accessToken"),
        },
    })
    let result = await response.json()
    return result
}

export default () => {
    const [state, setState] = useState({ loading: true, docs: [], page: 1, totalPages: 5, totalDocs: 0 })
    useEffect(() => {
        async function getTransferHistory() {
            let { docs, totalDocs, totalPages, page } = await loadTransfers({ page: state.page })
            setState({ docs, totalDocs, totalPages, page, loading: false })
        }

        getTransferHistory()
    }, [])

    const getTransfers = async (p) => {
        setState((state) => ({...state, page: p, loading: true }))
        let { docs, page, totalDocs, totalPages } = await loadTransfers({ page: p })
        setState({ docs, page, totalDocs, totalPages, loading: false })
    }

    const TableRow = ({ txn_reference_no, created_at, receive_account, receive_account_name, amount, fee }) => {
        return (
            <tr>
                <td>
                    {txn_reference_no}
                </td>
                <td>{created_at ? dayjs(created_at).format("DD/MM/YYYY") : ''}</td>
                <td>{created_at ? dayjs(created_at).format("HH:mm:ss") : ''}</td>
                <td>{receive_account}</td>
                <td>{receive_account_name}</td>
                <td>{amount ? numeral(amount).format("0,0.00") : ""}</td>
                <td>{fee ? numeral(fee).format("0,0.00") : ""}</td>
                <td>{amount && fee ? numeral( parseFloat(amount) + parseFloat(fee)).format("0,0.00") : ""}</td>
            </tr>
        )
    }
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Transfer history
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Transfer history</h4>
                </div>
            </div>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body>
                    {
                        state.loading ?
                            <div className="d-flex justify-content-center align-items-center" style={{minHeight: 250}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        : [

                            <Table
                                responsive
                                className="table-centered table-nowrap rounded mb-0"
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th className="border-0">Payment ID</th>
                                        <th className="border-0">Date</th>
                                        <th className="border-0">Time</th>
                                        <th className="border-0">Account number</th>
                                        <th className="border-0">Account Name</th>
                                        <th className="border-0">Amount</th>
                                        <th className="border-0">Fee</th>
                                        <th className="border-0">Total deduct</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.docs.length > 0 ? state.docs.map((item) => (
                                        <TableRow key={item.id} {...item} />
                                    )) : <tr key={'00'}><td colSpan={8}>Data not found</td></tr>}
                                </tbody>
                            </Table>,
                            <Pagination withIcons page={state.page} totalPages={state.totalPages} totalDocs={state.totalDocs} onPageChange={(p) => getTransfers(p)}/>
                        ]
                    }
                </Card.Body>
            </Card>
        </>
    )
}
