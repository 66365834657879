import React from "react"

export default ({ show }) => {
    return (
        <div
            className={`preloader bg-soft flex-column justify-content-center align-items-center ${
                show ? "" : "show"
            }`}
        >
            
        </div>
    )
}
