import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import dayjs from 'dayjs'
import numeral from 'numeral'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

import { Breadcrumb, Card, Table, Spinner } from "@themesberg/react-bootstrap"
import { FAN_API_URL } from "./../../config"
import Pagination from './../../components/Pagination'

async function loadFanLists({ id, page }) {
    let response = await fetch(`${FAN_API_URL}/projects/fan-lists/${id}?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " + localStorage.getItem("accessToken"),
        },
    })
    let res = await response.json()
    return res
}

export default () => {
    const [state, setState] = useState({ docs: [], page: 1, totalPages: 5, totalDocs: 0 })
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    useEffect(() => {
        async function getHistory() {
            let { docs, page, totalPages, totalDocs } = await loadFanLists({ id, page: state.page })
            setState({ docs, page, totalPages, totalDocs })
            setLoading(false)
        }

        getHistory()
    }, [])

    async function getHistory(p) {
        setLoading(true)
        setTimeout(async () => {
            let { docs, page, totalPages, totalDocs } = await loadFanLists({ id, page: p })
            setState({ docs, page, totalPages, totalDocs })
            setLoading(false)
        }, 500)
    }

    // const calFee = (amount) => {
    //     if(amount <= 100) 
    //         return amount - 1.5
    //     return amount * 0.01
    // }

    const TableRow = ({  name, phone, created_at, amount, ufc, fc, supporter, motivator, net_receive }) => {
        return (
            <tr>
                <td>{created_at ? dayjs(created_at).format("DD/MM/YYYY") : ''}</td>
                <td>{created_at ? dayjs(created_at).format("HH:mm:ss") : ''}</td>
                <td>{name || ""}</td>
                <td>{phone ? phone.replace("+66", "") : ""}</td>
                <td>
                    { ufc > 0 ? `${ufc} μfc ` : "" }
                    { fc > 0 ? `${fc} fc ` : "" }
                    { supporter > 0 ? `${supporter} supporter ` : "" }
                    { motivator > 0 ? `${motivator} motivator ` : "" }
                </td>
                <td>
                    { net_receive ? numeral(net_receive).format("0,0.00"): ""}
                </td>
            </tr>
        )
    }
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/fan/dashboard">Fan Token</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{id}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>{id}</h4>
                </div>
            </div>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body>
                    {
                        loading ?
                            <div className="d-flex justify-content-center align-items-center" style={{minHeight: 250}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        : [
                            <Table
                                responsive
                                className="table-centered table-nowrap rounded mb-0"
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th className="border-0">Date</th>
                                        <th className="border-0">Time</th>
                                        <th className="border-0">Name</th>
                                        <th className="border-0">Phone</th>
                                        <th className="border-0">Token</th>
                                        <th className="border-0">Net receive</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.docs.length > 0 ? state.docs.map((item) => (
                                        <TableRow key={item.id} {...item} />
                                    )) : <tr key={'00'}><td colSpan={8}>Data not found</td></tr>}
                                </tbody>
                            </Table>,
                            <Pagination withIcons page={state.page} totalPages={state.totalPages} totalDocs={state.totalDocs} onPageChange={(p) => getHistory(p)}/>
                        ]
                    }
                </Card.Body>
            </Card>
        </>
    )
}
