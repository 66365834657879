import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { API_URL } from "./../../config"
import { Breadcrumb, Card, Table } from "@themesberg/react-bootstrap"
import numeral from "numeral"

export default () => {
    const [state, setState] = useState({ docs: [] })
    useEffect(() => {
        async function getParkingFees() {
            let response = await fetch(`${API_URL}/payments/summary?service=parking`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            let { docs } = await response.json()
            setState({ docs: docs ? docs : [] })
        }

        getParkingFees()
    }, [])
    const padId = (id) => {
        return 'ORG'+(1e13 + "" + id).slice(-6)
    }
    const TableRow = ({ id, name, total, withdraw, balance }) => {
        return (
            <tr>
                <td>
                    <Link
                        to={`/parkingfee/${padId(id)}`}
                        className="text-primary fw-bold"
                    >
                        {padId(id)}
                    </Link>
                </td>
                <td>
                    <Link
                        to={`/parkingfee/${padId(id)}`}
                        className="text-primary fw-bold"
                    >
                        {name}
                    </Link>
                </td>
                <td>{total ? numeral(total).format("0,0.00") : ""}</td>
                <td>{withdraw ? numeral(withdraw).format("0,0.00") : ""}</td>
                <td>{balance ? numeral(balance).format("0,0.00") : ""}</td>
            </tr>
        )
    }
    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Parking Fee</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Parking Fee</h4>
                </div>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body>
                    <Table
                        responsive
                        className="table-centered table-nowrap rounded mb-0"
                    >
                        <thead className="thead-light">
                            <tr>
                                <th className="border-0">Org ID</th>
                                <th className="border-0">Sitename</th>
                                <th className="border-0">Total</th>
                                <th className="border-0">Withdraw</th>
                                <th className="border-0">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.docs && state.docs.length > 0 ? state.docs.map((item) => (
                                <TableRow key={item.id} {...item} />
                            )) : <tr key={'00'}><td colSpan={5}>Data not found</td></tr>}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}
