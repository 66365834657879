import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import numeral from "numeral"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { Breadcrumb, Card, Table } from "@themesberg/react-bootstrap"
import { FAN_API_URL, API_URL } from "./../../config"



function FanDashboard() {
    const [state, setState] = useState({ docs: [] })
    useEffect(() => {
        async function getProjects() {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            }
            let workResponse = await fetch(`${FAN_API_URL}/works`, {
                method: "GET",
                headers: headers,
            })
            let response = await fetch(`${FAN_API_URL}/project-lists`, {
                method: "GET",
                headers: headers,
            })
            
            let responseBalance = await fetch(`${API_URL}/payments/summary?service=fan_token`, {
                method: "GET",
                headers: headers,
            })
            let { data: dataWorks } = await workResponse.json()
            let { data } = await response.json()
            let { docs } = await responseBalance.json()
            let result = []
            let i = 0
            data = [...dataWorks, ...data]
            for(let d of data) {
                if(docs[i] && (docs[i].id === d.manager_org_id || docs[i].id === d.champion_org_id)) {
                    result.push({ ...docs[i], ...d, token: {}})
                } else if(docs[i] && docs[i].id === d.app_org_id) {
                    result.push({token: {}, ...docs[i], ...d})
                } else {
                    result.push({token: {}, ...d })
                }
                i++
            }
            setState({ docs: result })
        }

        getProjects()
    }, [])
    const TableRow = ({ slug, name, total, token, withdraw, balance }) => {
        return (
            <tr key={slug}>
                <td>
                    {
                        slug ?
                            <Link
                                to={`/fan/${slug}`}
                                className="text-primary fw-bold"
                            >
                                {name}
                            </Link>
                        : name
                    }
                </td>
                <td>
                    { token && token.ufc > 0 ? `${token.ufc} μfc ` : "" }
                    { token && token.fc > 0 ? `${token.fc} fc ` : "" }
                    { token && token.supporter > 0 ? `${token.supporter} supporter ` : "" }
                    { token && token.motivator > 0 ? `${token.motivator} motivator ` : "" }
                </td>
                <td>{numeral(total).format("0,0.00")}</td>
                <td>{numeral(withdraw).format("0,0.00")}</td>
                <td>{numeral(balance).format("0,0.00")}</td>
            </tr>
        )
    }
    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Fan Token</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Fan Token</h4>
                </div>
            </div>

            <Card border="light" className="shadow-sm mb-4">
                <Card.Body>
                    <Table
                        responsive
                        className="table-centered table-nowrap rounded mb-0"
                    >
                        <thead className="thead-light">
                            <tr>
                                <th className="border-0">Project</th>
                                <th className="border-0">Token</th>
                                <th className="border-0">Total</th>
                                <th className="border-0">Withdraw</th>
                                <th className="border-0">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.docs && state.docs.length > 0 ? state.docs.map((item) => (
                                <TableRow key={item.id} {...item} />
                            )) : <tr key={'00'}><td colSpan={5}>Data not found</td></tr>}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}

export default FanDashboard