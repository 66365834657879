import React, { useState, useContext } from "react"
import SimpleBar from "simplebar-react"
import { useLocation } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChartPie,
    faSignOutAlt,
    faTimes,
	faArrowUpFromBracket,
	faCar,
	faHandHoldingDollar,
    faStar
} from "@fortawesome/free-solid-svg-icons"
import {
    Nav,
    Badge,
    Image,
    Button,
    Dropdown,
    Navbar,
} from "@themesberg/react-bootstrap"
import { Link } from "react-router-dom"

import { Routes } from "../routes"
// import ReactHero from "../assets/img/technologies/react-hero-logo.svg"
import AppLogo from "../assets/img/logo-horizontal-w.png"
// import ProfilePicture from "../assets/img/team/profile-picture-3.jpg"
import { UserContext } from "../pages/HomePage"

export default () => {
    const location = useLocation()
    const userContext = useContext(UserContext)
    const { pathname } = location
    const [show, setShow] = useState(false)
    const showClass = show ? "show" : ""

    const onCollapse = () => setShow(!show)

    // const CollapsableNavItem = (props) => {
    //     const { eventKey, title, icon, children = null } = props
    //     const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : ""

    //     return (
    //         <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
    //             <Accordion.Item eventKey={eventKey}>
    //                 <Accordion.Button
    //                     as={Nav.Link}
    //                     className="d-flex justify-content-between align-items-center"
    //                 >
    //                     <span>
    //                         <span className="sidebar-icon">
    //                             <FontAwesomeIcon icon={icon} />{" "}
    //                         </span>
    //                         <span className="sidebar-text">{title}</span>
    //                     </span>
    //                 </Accordion.Button>
    //                 <Accordion.Body className="multi-level">
    //                     <Nav className="flex-column">{children}</Nav>
    //                 </Accordion.Body>
    //             </Accordion.Item>
    //         </Accordion>
    //     )
    // }

    const NavItem = ({
        title,
        link,
        external,
        target,
        icon,
        image,
        badgeText,
        badgeBg = "secondary",
        badgeColor = "primary",
    }) => {
        const classNames = badgeText
            ? "d-flex justify-content-start align-items-center justify-content-between"
            : ""
        const navItemClassName = link === pathname ? "active" : ""
        const linkProps = external ? { href: link } : { as: Link, to: link }

        return (
            <Nav.Item
                className={navItemClassName}
                onClick={() => setShow(false)}
            >
                <Nav.Link {...linkProps} target={target} className={classNames}>
                    <span>
                        {icon ? (
                            <span className="sidebar-icon">
                                <FontAwesomeIcon icon={icon} />{" "}
                            </span>
                        ) : null}
                        {image ? (
                            <Image
                                src={image}
                                width={20}
                                height={20}
                                className="sidebar-icon svg-icon"
                            />
                        ) : null}

                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge
                            pill
                            bg={badgeBg}
                            text={badgeColor}
                            className="badge-md notification-count ms-2"
                        >
                            {badgeText}
                        </Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        )
    }

    return (
        <>
            <Navbar
                expand={false}
                collapseOnSelect
                variant="dark"
                className="navbar-theme-primary px-4 d-md-none"
            >
                <Navbar.Brand
                    className="me-lg-5"
                    as={Link}
                    to={Routes.DashboardOverview.path}
                >
                    <Image src={AppLogo} className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle
                    as={Button}
                    aria-controls="main-navbar"
                    onClick={onCollapse}
                >
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition
                timeout={300}
                in={show}
                classNames="sidebar-transition"
            >
                <SimpleBar
                    className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
                >
                    <div className="sidebar-inner px-4 pt-3">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                {/* <div className="user-avatar lg-avatar me-4">
                                    <Image
                                        src={ProfilePicture}
                                        className="card-img-top rounded-circle border-white"
                                    />
                                </div> */}
                                <div className="d-block">
                                    <h6>
                                        {userContext?.user
                                            ? userContext.user.name
                                            : ""}
                                    </h6>
                                    <Button
                                        as={Link}
                                        variant="secondary"
                                        size="xs"
                                        to={Routes.Signin.path}
                                        className="text-dark"
                                    >
                                        <FontAwesomeIcon
                                            icon={faSignOutAlt}
                                            className="me-2"
                                        />{" "}
                                        Sign Out
                                    </Button>
                                </div>
                            </div>
                            <Nav.Link
                                className="collapse-close d-md-none"
                                onClick={onCollapse}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>
                        <Image
                            src={AppLogo}
                            className="card-img-top border-white"
                        />
                        <Nav className="flex-column pt-3 pt-md-0">
                            <Dropdown.Divider className="my-3 border-indigo" />
                            <NavItem
                                title="Overview"
                                link={Routes.DashboardOverview.path}
                                icon={faChartPie}
                            />
                            <NavItem
                                title="Parking Fee"
                                link={Routes.ParkingfeeDashboard.path}
                                icon={faCar}
                            />
                            <NavItem
                                title="Fan Token"
                                link={Routes.FanDashboard.path}
                                icon={faStar}
                            />
							 <NavItem
                                title="Withdraw"
                                link={Routes.Withdraw.path}
                                icon={faArrowUpFromBracket}
                            />
							<NavItem
                                title="Tranfer History"
                                link={Routes.TransferHistory.path}
                                icon={faHandHoldingDollar}
                            />
                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    )
}
