import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import HomePage from "./pages/HomePage"
import "./scss/volt.scss"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
    <BrowserRouter>
        <HomePage />
    </BrowserRouter>
)