
export const Routes = {
    // pages
    Presentation: { path: "/" },
    Callback: { path: "/callback" },
    DashboardOverview: { path: "/dashboard/overview" },
    FanDashboard: { path: "/fan/dashboard" },
    FanHistory: { path: "/fan/:id" },
    ParkingfeeDashboard: { path: "/parkingfee" },
    ParkingfeeHistory: { path: "/parkingfee/:orgid" },
    Withdraw: { path: "/withdraw" },
    TransferHistory: { path: "/transfer/history" },
    Profile: { path: "/profile" },
    Setting: { path: "/setting" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/examples/sign-in" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/examples/500" },
};