import { faHome, faWallet, faBahtSign } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import numeral from 'numeral'
import { Breadcrumb, Card, Col, Row } from "@themesberg/react-bootstrap"

import { API_URL } from './../../config'

const DashboardOverview = () => {
    const [balance, setBalance] = useState(0)
    useEffect(() => {
        async function getProjects() {
            let response = await fetch(`${API_URL}/payments/summary?service=fan_token`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("accessToken")
                }
            })
            let { docs } = await response.json()
            if(docs && docs[0] && docs[0].balance) {
                setBalance(docs[0].balance)
            }
        }

        getProjects()
    }, [])
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Overview
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Overview</h4>
                </div>
            </div>
            
            <Row>
                <Col xs={12} sm={6} xl={4} className="mb-4">
                    <Card border="light" className="shadow-sm">
                    <Card.Body>
                        <Row className="d-block d-xl-flex align-items-center">
                        <Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
                            <div className={`icon icon-shape icon-md icon-shape-tertiary rounded me-4 me-sm-0`}>
                                <FontAwesomeIcon icon={faWallet} />
                            </div>
                            <div className="d-sm-none">
                                <h5>Available Balance</h5>
                                <h3 className="mb-1">
                                    <FontAwesomeIcon icon={faBahtSign} size="xs" />
                                    {numeral(balance).format("0,0.00")}
                                </h3>
                            </div>
                        </Col>
                        <Col xs={12} xl={7} className="px-xl-0">
                            <div className="d-none d-sm-block">
                                <h5>Available Balance</h5>
                                <h3 className="mb-1">
                                    <FontAwesomeIcon icon={faBahtSign} size="xs" />
                                    {numeral(balance).format("0,0.00")}
                                </h3>
                            </div>
                            {/* <small> <FontAwesomeIcon icon={faGlobeEurope} size="xs" /> WorldWide</small>
                            <div className="small mt-2">
                            <FontAwesomeIcon icon={faAngleUp} className={`text-success me-1`} />
                            <span className={`text-success fw-bold`}>
                                18.2%
                            </span> Since last month
                            </div> */}
                        </Col>
                        </Row>
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default DashboardOverview
