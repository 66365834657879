import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import dayjs from 'dayjs'
import numeral from 'numeral'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

import { Breadcrumb, Card, Table, Spinner } from "@themesberg/react-bootstrap"
import { API_URL } from "./../../config"
import Pagination from './../../components/Pagination'

async function loadPayments({ orgid, page }) {
    let id = parseInt(orgid.replace("ORG", ""))
    let response = await fetch(`${API_URL}/payments?service=parking&orgId=${id}&page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " + localStorage.getItem("accessToken"),
        },
    })
    let res = await response.json()
    return res
}

export default () => {
    const [state, setState] = useState({ docs: [], page: 1, totalPages: 5, totalDocs: 0 })
    const [loading, setLoading] = useState(true)
    const { orgid } = useParams()
    useEffect(() => {
        async function getPayments() {
            let { docs, page, totalPages, totalDocs } = await loadPayments({ orgid, page: state.page})
            setState({ docs, page, totalPages, totalDocs })
            setLoading(false)
        }

        getPayments()
    }, [])

    async function getPayments(p) {
        setLoading(true)
        setTimeout(async () => {
            let { docs, page, totalPages, totalDocs } = await loadPayments({ orgid, page: p })
            setState({ docs, page, totalPages, totalDocs })
            setLoading(false)
        }, 500)
    }

    const TableRow = ({ biller_no, payment, created_at, payment_method, amount, fee }) => {
        return (
            <tr>
                <td>
                    {biller_no}
                </td>
                <td>{created_at ? dayjs(created_at).format("DD/MM/YYYY") : ''}</td>
                <td>{created_at ? dayjs(created_at).format("HH:mm:ss") : ''}</td>
                <td>{payment_method}</td>
                <td>{payment?.customer_name || ""}</td>
                <td>{numeral(amount).format('0,0.00')}</td>
                <td>{numeral(fee).format('0,0.00')}</td>
                <td>{numeral(amount - fee).format('0,0.00')}</td>
            </tr>
        )
    }
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/parkingfee">Parking Fee</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{orgid}</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>{orgid}</h4>
                </div>
            </div>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body>
                    {
                        loading ?
                            <div className="d-flex justify-content-center align-items-center" style={{minHeight: 250}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        : [
                            <Table
                                responsive
                                className="table-centered table-nowrap rounded mb-0"
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th className="border-0">Payment ID</th>
                                        <th className="border-0">Date</th>
                                        <th className="border-0">Time</th>
                                        <th className="border-0">Method</th>
                                        <th className="border-0">Name</th>
                                        <th className="border-0">Amount</th>
                                        <th className="border-0">Fee</th>
                                        <th className="border-0">Net</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.docs.length > 0 ? state.docs.map((item) => (
                                        <TableRow key={item.id} {...item} />
                                    )) : <tr key={'00'}><td colSpan={8}>Data not found</td></tr>}
                                </tbody>
                            </Table>,
                            <Pagination withIcons page={state.page} totalPages={state.totalPages} totalDocs={state.totalDocs} onPageChange={(p) => getPayments(p)}/>
                        ]
                    }
                </Card.Body>
            </Card>
        </>
    )
}
