import React, { useState, useEffect } from "react"
import { Row, Col, Pagination } from "@themesberg/react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons"

const CustomPagination = (props) => {
    const [activeItem, setActiveItem] = useState(1)
    const {
        page = 1,
        totalPages = 5,
        totalDocs = 0,
        size = "sm",
        withIcons = false,
        disablePrev = false,
        onPageChange = null
    } = props

    useEffect(() => {
        setActiveItem(page)
    }, [page])

    const onPrevItem = () => {
        const prevActiveItem = activeItem === 1 ? activeItem : activeItem - 1
        if(onPageChange) onPageChange(prevActiveItem)
        setActiveItem(prevActiveItem)
    }

    const onNextItem = (totalPages) => {
        const nextActiveItem =
        activeItem === totalPages ? activeItem : activeItem + 1
        if(onPageChange) onPageChange(nextActiveItem)
        setActiveItem(nextActiveItem)
    }

    const items = []
    for (let number = 1; number <= totalPages; number++) {
        const isItemActive = activeItem === number

        const handlePaginationChange = () => {
            if(onPageChange) onPageChange(number)
            setActiveItem(number)
        }

        items.push(
            <Pagination.Item
                active={isItemActive}
                key={number}
                onClick={handlePaginationChange}
            >
                {number}
            </Pagination.Item>
        )
    }

    let fromN = (page * 10 - 10) + 1
    let toN = (page * 10 - 10) + 10
    return (
        <Row>
            <Col className="pt-3">
                <small>Showing {fromN} to {toN >= totalDocs ? totalDocs : toN} of {totalDocs} entries</small>
            </Col>
            <Col className="text-right">
                <Pagination size={size} className="mt-3 justify-content-end">
                    <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
                        {withIcons ? (
                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        ) : (
                            "Previous"
                        )}
                    </Pagination.Prev>
                    {items}
                    <Pagination.Next onClick={() => onNextItem(totalPages)}>
                        {withIcons ? (
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        ) : (
                            "Next"
                        )}
                    </Pagination.Next>
                </Pagination>
            </Col>
        </Row>
    )
}

export default CustomPagination
