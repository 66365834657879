import React, { useState, useEffect, useContext } from "react"
import numeral from "numeral"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faHome,
    faArrowRight,
    faArrowLeft,
    faCheck,
    faCheckCircle
} from "@fortawesome/free-solid-svg-icons"
import Stepper from "./../../components/Stepper"
import config from "./../../config"
import {
    Breadcrumb,
    Card,
    InputGroup,
    Form,
    Row,
    Col,
    Button,
    Spinner,
    Alert
} from "@themesberg/react-bootstrap"
import { UserContext } from "../HomePage"
import dayjs from 'dayjs'

// const bank_lists = [
//     { bank_code: "002", bank_name: "กรุงเทพ", bank_short_name: "BBL", swift_code: "BKKBTHBK" }, //
//     { bank_code: "004", bank_name: "กสิกรไทย", bank_short_name: "KBANK", swift_code: "KASITHBK" }, //
//     { bank_code: "006", bank_name: "กรุงไทย", bank_short_name: "KTB", swift_code: "KRTHTHBK" }, //
//     { bank_code: "011", bank_name: "ทหารไทยธนชาต", bank_short_name: "TTB", swift_code: "TMBKTHBK" }, //
//     { bank_code: "014", bank_name: "ไทยพาณิชย์", bank_short_name: "SCB", swift_code: "SICOTHBK" }, //
//     { bank_code: "017", bank_name: "ซิตี้แบงก์", bank_short_name: "CITI", swift_code: "CITITHBX" }, //
//     { bank_code: "025", bank_name: "กรุงศรีอยุธยา", bank_short_name: "BAY", swift_code: "AYUDTHBK" }, //
//     { bank_code: "069", bank_name: "เกียรตินาคินภัทร", bank_short_name: "KKP", swift_code: "KIFITHB1" },
//     { bank_code: "022", bank_name: "ซีไอเอ็มบีไทย", bank_short_name: "CIMBT", swift_code: "UBOBTHBK" }, //
//     // { bank_code: "067", bank_name: "ทิสโก้", bank_short_name: "TISCO", swift_code: "TFPCTHB1" },
//     { bank_code: "024", bank_name: "ยูโอบี", bank_short_name: "UOBT", swift_code: "UOVBTHBK" }, //
//     { bank_code: "071", bank_name: "ไทยเครดิตเพื่อรายย่อย", bank_short_name: "TCD", swift_code: "THCETHB1" },
//     { bank_code: "073", bank_name: "แลนด์ แอนด์ เฮ้าส์", bank_short_name: "LHFG", swift_code: "LAHRTHB1" }, //
//     // { bank_code: "070", bank_name: "ไอซีบีซี (ไทย)", bank_short_name: "ICBCT", swift_code: "ICBKTHBK" },
//     // { bank_code: "098", bank_name: "พัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย", bank_short_name: "SME", swift_code: "-" },
//     // { bank_code: "034", bank_name: "เพื่อการเกษตรและสหกรณ์การเกษตร", bank_short_name: "BAAC", swift_code: "BAABTHBK" },
//     // { bank_code: "035", bank_name: "เพื่อการส่งออกและนำเข้าแห่งประเทศไทย", bank_short_name: "EXIM", swift_code: "EXTHTHBK" },
//     // { bank_code: "030", bank_name: "ออมสิน", bank_short_name: "GSB", swift_code: "GSBATHBK" },
//     // { bank_code: "033", bank_name: "อาคารสงเคราะห์", bank_short_name: "GHB", swift_code: "-" },
//     // { bank_code: "066", bank_name: "อิสลามแห่งประเทศไทย", bank_short_name: "ISBT", swift_code: "TIBTTHBK" },
// ]

const TransferDescription = ({
    id,
    amount,
    fee,
    bankName,
    accountName,
    accountNumber,
    notes
}) => {
    const padId = (id) => {
        return "ORG" + (1e13 + "" + id).slice(-6)
    }
    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>From</Form.Label>
                <div className="row mb-1">
                    <div className="col-sm-6">ORG ID:</div>
                    <div className="col-sm-6 text-right">
                        {id ? padId(id) : ""}
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-sm-6">Amount:</div>
                    <div className="col-sm-6 text-right">
                        {numeral(amount).format("0,0.00")}
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-sm-6">Fee:</div>
                    <div className="col-sm-6 text-right">
                        {numeral(fee).format("0,0.00")}
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-sm-6">Total deducted:</div>
                    <div className="col-sm-6 text-right">
                        {numeral(parseFloat(amount) + parseFloat(fee)).format("0,0.00")}
                    </div>
                </div>
            </Form.Group>
            <hr />
            <Form.Group className="mb-3">
                <Form.Label>To</Form.Label>
                <div className="row mb-1">
                    <div className="col-sm-6">Bank name</div>
                    <div className="col-sm-6 text-right">{bankName || ""}</div>
                </div>
                <div className="row mb-1">
                    <div className="col-sm-6">Account number</div>
                    <div className="col-sm-6 text-right">
                        {accountNumber || ""}
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-sm-6">Account name</div>
                    <div className="col-sm-6 text-right">
                        {accountName || ""}
                    </div>
                </div>
            </Form.Group>
            <hr />
            <Form.Group className="mb-3">
                <div className="row">
                    <div className="col-sm-6">Notes</div>
                    <div className="col-sm-6 text-right">{notes || ""}</div>
                </div>
            </Form.Group>
        </>
    )
}

export default () => {
    // const [expiryTime, setExpiryTime] = useState("15 oct 2022 18:00:00");
    // const [countdownTime, setCountdownTime] = useState({
    //     countdownDays: "",
    //     countdownHours: "",
    //     countdownlMinutes: "",
    //     countdownSeconds: "",
    // });
    const [alert, setAlert] = useState({})
    const [step, setStep] = useState(1)
    const [org, setOrg] = useState({})
    const [account, setAccount] = useState({ docs: [] })
    const [state, setState] = useState({ docs: [] })
    const [fans, setFans] = useState({ docs: [] })
    const [data, setData] = useState({ orgId: 0, amount: 0, account: {}, fee: 0, ref_code: "", otp_code: "", notes: "", loading: false, service_name: "" })
    const [wresponse, setWresponse] = useState({})
    const userContext = useContext(UserContext)
    const nextStep = () => {
        setStep(step + 1)
    }
    const backStep = () => {
        setStep(step - 1)
    }
    useEffect(() => {
        async function getParkingFees() {
            let response = await fetch(
                `${config.API_URL}/payments/summary?service=parking`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + localStorage.getItem("accessToken"),
                    },
                }
            )
            let { docs } = await response.json()
            let fanResponse = await fetch(
                `${config.API_URL}/payments/summary?service=fan_token`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + localStorage.getItem("accessToken"),
                    },
                }
            )
            let res = await fanResponse.json()
            setFans({ docs: res.docs ? res.docs : []  })
            setState({ docs: docs ? docs : []  })
        }

        const getAccounts = async () => {
            let response = await fetch(`${config.API_URL}/bank-accounts`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            let { docs } = await response.json()
            setAccount({ docs })
        }

        getParkingFees()
        getAccounts()
    }, [])

    const padId = (id) => {
        return "ORG" + (1e13 + "" + id).slice(-6)
    }

    const handleChange = (e) => {
        let {value} = e.target
        let [svc, id] = value.split("-")
        console.log(svc)
        console.log(id)
        if(svc === "parking") {
            let item = state.docs.filter(
                (d) => parseInt(d.id) === parseInt(id)
            )
            if (item[0]) {
                setOrg(item[0])
                setData((data) => ({...data, orgId: item[0].id, service_name: "parking"}))
            }
        }
        if(svc === "fan_token") {
            let item = fans.docs.filter(
                (d) => parseInt(d.id) === parseInt(id)
            )
            if (item[0]) {
                setOrg(item[0])
                setData((data) => ({...data, orgId: item[0].id, service_name: "fan_token"}))
            }
        }
    }

    const onChange = (e) => {
        setAlert({})
        setData((data) => ({ ...data, [e.target.name]: e.target.value, fee: 25 }))
    }

    const changeAccount = (e) => {
        let acc =
            account.docs[
                account.docs.findIndex(
                    (x) => parseInt(x.ID) === parseInt(e.target.value)
                )
            ]
        setData((data) => ({ ...data, account: acc }))
    }

    const confirmTransfer = async () => {
        setData((data) => ({ ...data, loading: true }))
        
        try {
            let response = await fetch(`${config.API_URL}/withdraw`, {
                method: "POST",
                body: JSON.stringify({
                    amount: data.amount,
                    receive_account: data.account?.account_number,
                    receive_bank_code: data.account?.bank_code,
                    ref_code: data.ref_code,
                    otp_code: data.otp_code,
                    notes: data.notes,
                    service_name: data.service_name
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("accessToken"),
                },
            })
            let res = await response.json()
            setWresponse(res.data)
            setData((data) => ({ ...data, loading: false }))
            if(res.response_status && res.response_status.ResponseCode === "ATS-I-1000") {
                nextStep()
                setWresponse(res.result)
            }
            if(res.status === "error") {
                setAlert(res)
            }
        } catch(err) {
            setData((data) => ({ ...data, loading: false }))
            setAlert(err.response)
        }
        
    }

    const getCode = async () => {
        let response = await fetch(`${config.API_URL}/get-otp-code`, {
            method: "POST",
            body: JSON.stringify({
                service_name: "withdraw",
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
            },
        })
        let res = await response.json()
        if(res.status === "success") {
            setData((data) => ({ ...data, ref_code: res.data.ref_code, loading: false }))
        }
    }

    const nextStepAndGetCode = async () => {
        setData((data) => ({ ...data, loading: true }))
        await getCode()
        nextStep()
    }

    const nextToStep2 = () => {
        if(data.orgId === 0) {
            setAlert({ status: "warning", message: "Org ID invalid", fields: { org: "Org ID invalid" } })
            return
        }
        if(data.amount <= 0) {
            setAlert({ status: "warning", message: "Amount invalid", fields: { org: "Amount invalid" } })
            return
        }
        if( (org.balance - (parseFloat(data.amount) + data.fee) ) < 0) {
            setAlert({ status: "warning", message: "Amount invalid", fields: { amount: "Amount invalid" } })
            return
        }
        if(data.notes.length > 0) {
            var iChars = "!@#$%^&*()+=-_[]\\\';,./{}|\":<>?";
            console.log(data.notes.indexOf(iChars))
            for (var i = 0; i < data.notes.length; i++) {
                if (iChars.indexOf(data.notes.charAt(i)) != -1) {
                    setAlert({ status: "warning", message: "Notes special char invalid", fields: { notes: "Notes  special char invalid" } })
                    return
                }
            }
            
        }
        nextStep()
        setAlert({})
    }
    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Withdraw</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Withdraw</h4>
                </div>
            </div>
            <Row className="justify-content-center">
                <Col lg={8} sm={12}>
                    <Stepper step={step} />
                    {
                        alert && alert.status && <Alert variant={alert.status === "error" ? "danger" : alert.status}>{alert.message}</Alert>
                    }
                    {step === 1 && (
                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Org ID</Form.Label>
                                        <Form.Select onChange={handleChange}>
                                            <option value="">
                                                -- select org id --
                                            </option>
                                            {state.docs.length > 0
                                                ? state.docs.map((item) => (
                                                      <option
                                                          key={item.id}
                                                          value={`parking-${item.id}`}
                                                      >
                                                           {padId(item.id)} Parking
                                                      </option>
                                                  ))
                                                : null}
                                            {fans.docs.length > 0
                                                ? fans.docs.map((item) => (
                                                      <option
                                                          key={item.id}
                                                          value={`fan_token-${item.id}`}
                                                      >
                                                          {padId(item.id)} Fan Token
                                                      </option>
                                                  ))
                                                : null}
                                        </Form.Select>
                                        {alert.fields && alert.fields.org && <Form.Control.Feedback type="invalid">{alert.fields.org}</Form.Control.Feedback>}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Available Balance
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                disabled
                                                className="text-right"
                                                type="text"
                                                placeholder="0.00"
                                                value={
                                                    org && org.id
                                                        ? numeral(org.balance).format("0,0.00")
                                                        : "0.00"
                                                }
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Withdraw amount</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                name="amount"
                                                className="text-right"
                                                type="text"
                                                placeholder={"0.00"}
                                                onChange={onChange}
                                            />
                                            {alert.fields && alert.fields.amount && <Form.Control.Feedback type="invalid">{alert.fields.amount}</Form.Control.Feedback>}
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Withdraw fee</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                disabled
                                                className="text-right"
                                                type="text"
                                                placeholder="0.00"
                                                value={
                                                    data.fee
                                                        ? numeral(
                                                              data.fee
                                                          ).format("0,0.00")
                                                        : "0.00"
                                                }
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Total deducted</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                disabled
                                                className="text-right"
                                                type="text"
                                                placeholder="0.00"
                                                value={
                                                    data.amount > 0
                                                        ? numeral(
                                                              parseFloat(data.amount) + data.fee
                                                          ).format("0,0.00")
                                                        : "0.00"
                                                }
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Net balance remain</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                disabled
                                                className="text-right"
                                                type="text"
                                                placeholder="0.00"
                                                value={
                                                    data.amount > 0
                                                        ? numeral(
                                                              org.balance - (parseFloat(data.amount) + data.fee)
                                                          ).format("0,0.00")
                                                        : "0.00"
                                                }
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Notes</Form.Label>
                                        <Form.Control as="textarea" rows="3" name="notes" onChange={onChange} value={data.notes} />
                                        {alert.fields && alert.fields.notes && <Form.Control.Feedback type="invalid">{alert.fields.notes}</Form.Control.Feedback>}
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
                    {step === 2 && (
                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Bank account</Form.Label>
                                        <Form.Select onChange={changeAccount}>
                                            <option value="">
                                                {" "}
                                                -- select account --{" "}
                                            </option>
                                            {account &&
                                                account.docs.map((acc) => (
                                                    <option
                                                        key={acc.ID}
                                                        value={acc.ID}
                                                    >
                                                        {acc.account_name}{" "}
                                                        {"(" +
                                                            acc.bank_name +
                                                            ")"}{" "}
                                                        <br />{" "}
                                                        {acc.account_number}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
                    {step === 3 && (
                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body>
                                <Form>
                                    <TransferDescription
                                        id={org.id}
                                        amount={data.amount}
                                        fee={data.fee}
                                        bankName={data.account?.bank_name || ""}
                                        accountName={
                                            data.account?.account_name || ""
                                        }
                                        accountNumber={
                                            data.account?.account_number || ""
                                        }
                                        notes={data.notes}
                                    />
                                    <hr />
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            { userContext.user && userContext.user.phone ? 'Phone' : 'Email ' } Verification Code
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Control type="text" placeholder="" value={data.otp_code} onChange={(e) => setData((data) => ({...data, otp_code: e.target.value}))}/>
                                            {/* <InputGroup.Text>
                                                <Button bsPrefix="text" href="#" onClick={getCode} variant="primary">Get Code</Button>
                                            </InputGroup.Text> */}
                                        </InputGroup>
                                        <span>
                                            Enter the 6-digit code sent to 
                                            {userContext.user && userContext.user.email
                                                ? userContext.user.email.replace(/^(.{3})[^@]+/, " $1***")
                                                : ""}
                                            {userContext.user && userContext.user.phone
                                                ? userContext.user.phone.replace(userContext.user.phone.substring(0,8), " ******")
                                                : ""}
                                        </span>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
                    {step === 4 && (
                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body>
                                <Form>
                                    <div className="row mb-2">
                                        <div className="col-sm-12 text-center">
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                color="green"
                                                size="2x"
                                            />
                                            <br/>
                                            <span> Withdraw Success</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 text-center">
                                            <small>{wresponse.docid}</small>
                                            <br />
                                            <small>{wresponse.created_at ? dayjs(wresponse.created_at).format("DD/MM/YYYY HH:mm:ss") : ""}</small>
                                        </div>
                                    </div>
                                    <TransferDescription
                                        id={org.id}
                                        amount={data.amount}
                                        fee={data.fee}
                                        bankName={data.account?.bank_name || ""}
                                        accountName={
                                            data.account?.account_name || ""
                                        }
                                        accountNumber={
                                            data.account?.account_number || ""
                                        }
                                        notes={data.notes}
                                    />
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
                    <div className="d-flex justify-content-center mb-3">
                        {step === 1 && (
                            <Button
                                onClick={nextToStep2}
                                variant="outline-primary"
                            >
                                Next <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        )}
                        {step >= 2 && step <= 3 && (
                            <Button
                                className="mx-1"
                                onClick={backStep}
                                variant="outline-primary"
                                disabled={data.loading}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                            </Button>
                        )}
                        {step === 2 && (
                            <Button
                                onClick={nextStepAndGetCode}
                                variant="outline-primary"
                                disabled={data.account && data.account.ID ? (data.loading ? true : false) : true}
                            >
                                {!data.loading
                                    ? [ "Next ", <FontAwesomeIcon icon={faArrowRight} />]
                                    : [
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />,
                                        " Loading...",
                                    ]}
                            </Button>
                        )}
                        {step === 3 && (
                            <Button
                                variant="outline-primary"
                                onClick={confirmTransfer}
                                disabled={data.loading || data.otp_code.length !== 6}
                            >
                                {data.loading
                                    ? [
                                          <Spinner
                                              as="span"
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                          />,
                                          " Loading...",
                                      ]
                                    : [
                                          "Confirm ",
                                          <FontAwesomeIcon icon={faCheck} />,
                                      ]}
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    )
}
