import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

import { Breadcrumb, Card } from "@themesberg/react-bootstrap"

const Profile = () => {
   
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Profile
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Profile</h4>
                </div>
            </div>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body>
                   
                </Card.Body>
            </Card>
        </>
    )
}

export default Profile